(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-wager-draw-event-start-time.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-wager-draw-event-start-time.js');
"use strict";


const {
  useWagerDrawEvents
} = svs.components.tipsen.hooksWager;
const {
  getIsCancelled
} = svs.components.sportinfo.common.matchState;
const {
  useMemo
} = React;
const getDrawEventsStartTime = drawEvents => drawEvents === null || drawEvents === void 0 ? void 0 : drawEvents.map(event => ({
  eventStartTime: new Date(event.matchStart),
  isCancelled: getIsCancelled(event.sportEventStatus)
}));

const useWagerDrawEventStartTime = _ref => {
  let {
    wagerId
  } = _ref;
  const wagerDrawEvents = useWagerDrawEvents({
    wagerId
  });
  const wagerDrawEventStartTime = useMemo(() => getDrawEventsStartTime(wagerDrawEvents), [wagerDrawEvents]);
  return wagerDrawEventStartTime;
};
setGlobal('svs.components.tipsen.hooksWager.useWagerDrawEventStartTime', useWagerDrawEventStartTime);

 })(window);