(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-wager-draw-events.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-wager-draw-events.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  selectSportEvents
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  useGetWagerDraw
} = svs.components.tipsen.hooksWager;
const {
  useMemo
} = React;
const getDrawEvents = (wagerDraw, sportEvents) => {
  var _wagerDraw$draw;
  const drawEvents = [];
  wagerDraw === null || wagerDraw === void 0 || (_wagerDraw$draw = wagerDraw.draw) === null || _wagerDraw$draw === void 0 || _wagerDraw$draw.drawEvents.forEach(drawEvent => {
    if (sportEvents[drawEvent.matchId]) {
      drawEvents.push(sportEvents[drawEvent.matchId]);
    }
  });
  return drawEvents;
};

const useWagerDrawEvents = _ref => {
  let {
    wagerId
  } = _ref;
  const wagerDraw = useGetWagerDraw({
    wagerId
  });
  const sportEvents = useSelector(state => selectSportEvents(state));
  const drawEvents = useMemo(() => getDrawEvents(wagerDraw, sportEvents), [wagerDraw, sportEvents]);
  return drawEvents;
};
setGlobal('svs.components.tipsen.hooksWager.useWagerDrawEvents', useWagerDrawEvents);

 })(window);