(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-wager-due-date.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-wager-due-date.js');
"use strict";


const {
  useGetWagerDraw,
  useWagerDrawEventStartTime,
  useGetDrawState
} = svs.components.tipsen.hooksWager;
const {
  useGetPlayerWagerQuery
} = svs.components.tipsen.store.tipsenApi;
const {
  drawStateEnums
} = svs.components.sport.tipsenShared;
const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const getLastMatchStartTime = drawEventsStartTime => {
  const startTimesWithoutCancelledEvents = drawEventsStartTime.filter(event => !event.isCancelled);
  const startTimes = startTimesWithoutCancelledEvents.map(event => event.eventStartTime);
  const lastMatchStartTime = startTimes.sort(dateFns.compareDesc)[0];

  if (dateFns.isPast(lastMatchStartTime)) {
    return new Date();
  }
  return lastMatchStartTime;
};

const getDateForMultipleDraws = (productBet, drawEventsStartTime) => {
  let dateToUse;
  if (productBet.completedDrawCount === productBet.totalDrawCount - 1) {
    dateToUse = getLastMatchStartTime(drawEventsStartTime);
  } else {
    dateToUse = productBet.lastDrawDate;
  }
  return dateToUse;
};

const useWagerDueDate = _ref => {
  let {
    wagerId
  } = _ref;
  const wager = useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const {
    draw
  } = useGetWagerDraw({
    wagerId
  });
  const drawEventsStartTime = useWagerDrawEventStartTime({
    wagerId
  });
  const drawState = useGetDrawState(draw === null || draw === void 0 ? void 0 : draw.productId, draw === null || draw === void 0 ? void 0 : draw.drawNumber);
  if (!draw || !wager) {
    return undefined;
  }
  const drawIsFinalized = drawState === drawStateEnums.FINALIZED;
  let dueToDate;
  if (drawIsFinalized) {
    const completedOnDate = wager.data.productBet.completedOn || wager.data.productBet.lastDrawDate;
    dueToDate = formatDate(completedOnDate, true, true, true);
  } else {
    let lastMatchStart;
    if (wager.totalDrawCount > 1) {
      lastMatchStart = getDateForMultipleDraws(wager.data.productBet, drawEventsStartTime);
    } else {
      lastMatchStart = getLastMatchStartTime(drawEventsStartTime);
    }
    dueToDate = formatDate(lastMatchStart, false, true, true);
  }
  return dueToDate;
};
setGlobal('svs.components.tipsen.hooksWager.useWagerDueDate', useWagerDueDate);

 })(window);